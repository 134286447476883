<template>
  <div>
    <ul>
      <li v-for="item of routeArr" :key="item.path">
        <router-link :to="item.path">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { routes } from '@/router/index'
export default {
  data() {
    return {
      routeArr: []
    }
  },

  created() {
    this.getRouteArr();
  },

  methods: {
    getRouteArr() {
      console.log(routes);
      this.routeArr = routes.filter(item => {
        if(item.hidden) { return true }
        return false
      }).map(item => {
        const { title} = item.meta;
        return {
          name: title,
          path: item.path
        }
      })
    }
  }
}
</script>

<style scoped>

</style>