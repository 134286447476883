<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { strRSA } from '@/utils/RSA_util';
export default {
  name: 'App',
  mounted() {
    window.addEventListener('message', (event) => {
      if (event.data.token) {
        sessionStorage.setItem('token', strRSA(event.data.token));
      }
    }, false);
    if ((window.self === window.top)) {
      const token1 = sessionStorage.getItem('token');
      if (!token1) {
        this.$router.push({ path: '/login' });
      }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.path !== '/login') {
          sessionStorage.setItem('lastHistory', route.path);
        }
      },
      immediate: true
    }
  },
  created() {
    const goURLStr = this.goHTTPS();
    goURLStr && window.location.replace(goURLStr);
  },
  methods: {
    goHTTPS() {
      const ENV = process.env.NODE_ENV;
      // console.log(ENV);
      const href = window.location.href;
      // 开发环境
      if (ENV === 'development') {
        return '';
      }

      // 正式环境
      const { protocol, pathname, host } = new URL(href);

      // 当前已经是https了
      if (protocol === 'https:') {
        return '';
      }
      return `https://${host}${pathname}`;
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
