import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/index.vue';

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    alias: '/index',
    name: 'index',
    component: Index,
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    meta: { title: '登录' }
  },
  {
    path: '/orderBusiness',
    component: () => import('@/views/orderBusiness/index.vue'),
    meta: { title: '安骏订单业务大屏' },
    hidden: true
  },
  {
    path: '/sorterKanban',
    component: () => import('@/views/sorterKanban/index.vue'),
    meta: { title: '安骏分拣业务大屏' },
    hidden: true
  },
  {
    path: '/bulletinboard',
    component: () => import('@/views/BulletinBoard/index.vue'),
    meta: { title: '客户看板' },
    hidden: true
  },
  {
    path: '/BusinessOverview',
    component: () => import('@/views/BusinessOverview/index.vue'),
    meta: { title: '公司业务概况' },
    hidden: true
  },
  { path: '*', redirect: '/index' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
