import Vue from 'vue'
// import './plugins/axios'
import App from './App.vue'
import router from './router'
import './assets/common.css';

// 引入DaTa V
import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

import defaultSetting from '../setting';
router.beforeEach((to, from, next) => {
  // 用于设置 浏览器的 title 显示
  if (to.meta.title) {
    document.title = `${to.meta.title}`
  } else {
    document.title = `${defaultSetting .title}`
  }
  next()
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
